.Destination{
    margin: 4rem 6rem;

}
.Destination h1{
    font-size: 3rem;
}
.first-Des{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
}
.des-text{
    width: 45%;
    font-size: 1.1rem;
    text-align: start;
}
.des-text h2{
    font-size: 2rem;
    margin-bottom: 1rem;
}
.des-text p{
    font-size: 0.9rem;
    margin-bottom: 1rem;
}
.des-img{
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    z-index: -99;
}
.des-img img{
  width: 49%;
  height: 350px;
  object-fit: cover;
  border-radius: 6px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.7);
}
.des-img img:nth-child(2){
    position: absolute;
    top: -10%;
    right: 0;

}

@media screen and (max-width: 850px){
    .Destination{
        margin: 4rem 2rem;
    
    }
    .first-Des{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 2.5rem;
    }
    .des-text{
        width: 100%;
    }
    .des-img{
        width: 100%;
        margin: 1.8rem 0;
    }
    .des-img img{
        height: 250px;
    }
}