.Contact{
    padding: 4rem 6rem;
    color: black;
}
.Contact form{
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto;
}
.Contact input{
    padding: 0 1rem;
    height: 3rem;
    margin-bottom: 1rem;
    border-radius: .3rem;
    border: 1px solid black;
}
.Contact textarea{
    padding: 0 1rem;
    /* height: 3rem; */
    margin-bottom: 1rem;
    border-radius: .3rem;
    border: 1px solid black;
}
.Contact button{
    display: inline;
    padding:1rem 0;
    /* background-color: black; */
}

@media screen  and (max-width: 850px){
    .Contact{
        padding: 4rem 2rem;

    }
    .Contact form{
        width: 90%;
        padding-top: 2rem;
    }
    
}