
.footer{
    padding: 4rem 6rem;
    background: black;
    color: white;
}
.Top{
    display: flex;
    text-align: start;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.Top i{
    color: white;
    margin-left: 1rem;
    font-size: 2rem;
}
.Top i:hover{
     color: #019590;
}
.Buttom{
    display: flex;
    text-align: start;
    padding-top: 2rem;
    justify-content: space-between;
    flex-wrap: wrap;

}

.Buttom div{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}
.Buttom h4{
    font-size: 1.3rem;
    padding: 1rem 0 1rem 0;

}
.Buttom a{
    text-decoration: none;
    color: white;
    font-size: 1.1rem;
    padding-bottom: .5rem;
}

@media screen  and (max-width: 850px){
    .footer{
        padding: 4rem 2rem;
    }
    .footer i{
        margin: 1rem 1rem 0 0;
    }
    .Buttom div{
        width: 50%;
    }
}
