.about{
    padding: 4rem 6rem;
    text-align: start;
}
.about h1{
    padding-bottom: 1rem;
}
.about p{
    padding-bottom: 2rem;
}
@media screen and (min-width: 768px{
    .about{
        padding: 4rem 2rem;
    }
    
}