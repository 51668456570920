.trip{
    margin: 4rem 6rem;
    color: black;
}
.trip h1{
    font-size: 3rem;
}

.trips{
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
}
.t-card{
    width: 32%;
    text-align: start;
    padding: 1rem .8rem;
    border-radius: 7px;
    box-shadow: 0  5px 25px 2px rgba(0,0,0,0.2);
}
.t-img{
    height: 200px;
    overflow: hidden;
    border-radius: 7px;
    transition: 0.3s ease-in-out;
}
.t-img img{
    width: 100%;
    height: 100%;
}
.t-img:hover img{
    transform: scale(1.3);
}
.t-card h4{
    padding: 0.9rem 0 .2rem 0;
    font-size: 1.3rem;
}
.t-card p{
    font-size: .8rem;
}

@media screen  and (max-width: 850px){
    .trip{
        margin: 4rem 2rem;
    }
    .trips{
        display: flex;
        flex-direction: column;
    }
    .t-card{
        width: 100%;
        margin-bottom: 1.5rem;
    }
    
}