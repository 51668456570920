.Navitems{
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    height: 80px;
    border-radius: 13px;
    padding: 0 30px;
    box-shadow: 0  5px 15px 0 rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 20px;
    left: 2.5%;
    z-index: 9999;

}
.Navbar-logo{
    color: black;
    font-size: 2rem;
    cursor: pointer;
}
.Nav-menu{
    display: grid;
    grid-template-columns: repeat(6 ,auto);
    grid-gap: 10px;
    align-items: center;
    list-style: none;

}
.nav-links{
 text-decoration: none;
 color: black;
 padding: 0.7rem 1rem;
 font-size: 1,2rem;
 font-weight: 600;
 white-space: nowrap;
}
.nav-links i{
    padding-right: 10px;

}
.nav-links:hover{
    background-color: #01959a;
    color: white;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}
.menu-icon{
    display: none;
}


@media screen and (max-width: 768px){
    .Navitems{
        z-index: 99;
        height: 50px;
    }
    .menu-icon{
        display: block;
        cursor: pointer;
    }
    .menu-icon i{
        font-size: 1.2rem;
        color: black;
    }
    .Nav-menu{
        background-color: rgba(255, 255, 255, 0.358);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        /* background-color: #4a4d4e; */
        color: white;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: -100%;
        /* opacity: 1; */
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        transition: 0.4s ease-in-out;
        /* z-index: -1; */
    }
    .Nav-menu.active{
        left: 0;
        opacity: 1;
        z-index: -1;
        transition: 0.4s ease-in-out;
    }
    .nav-links{
        display: block;
        width: 100%;
        font-size: 1.2rem;
        /* padding: 2rem 0; */
    }
    .nav-links:hover{
        background-color: #01959a;
        transition: none;
    }
    
}