
.hero{
    width: 100%;
    height: 90vh;
    position: relative;
}
.hero-mid{
    width: 100%;
    height: 70vh;
    position: relative;
}
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.Hero-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
.Hero-text h1{
    font-size: 3rem;
    font-weight: 800;
    color: white;
}
.Hero-text p{
    font-size: 1.3rem;
    /* font-weight: 800; */
    color: rgb(6, 6, 6);
    padding: 2rem 2rem;
}
.Hero-text .btn{
    text-decoration: none;
    font-size: 1.2rem;
    background-color: white;
    padding: 1rem 2rem;
    border-radius: 6px;
    letter-spacing: 2px;
    /* margin: 100px; */
    font-weight: 800;
    color: black;
}
.hide{
    display: none;
}


@media screen and (max-width: 768px) {
 
    .Hero-text h1{
        padding: 10px 20px;
        font-size: 1.5rem;
    }
    .Hero-text p{
      padding: 0 0 2rem 0;
      font-size: 0.9rem;
    }
    .Hero-text .btn{
        padding: 0.6rem 1.1rem;
        border-radius: 6px;
        font-size: 1rem;
    }
}